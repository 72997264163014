.roam-selection-dialog {
  --color-primary: #91466a;
  --color-primary-fg: #f1f1f1;
  --text-input-color: #b2b2b2;
  --text-base: 0.9rem;
  --gap-sm: 0.5rem;
  --gap-base: 1rem;
  --gap-md: 1.5rem;
  --selection-list-max-height: 40svh;
  --font-semi-bold: 500;
  --button-radius: 1.5rem;
  --letter-spacing: normal;
  --button-height: 2.5rem;
  --rounded-sm: 0.25rem;
  --rounded-full: 50%;

  // overrides
  --mat-form-field-container-text-size: var(--text-base);

  display: block;
  position: relative;

  * {
    letter-spacing: var(--letter-spacing);
  }

  .message {
    font-size: var(--font-size);
  }

  .selection-dialog-content {
    padding: 0 !important;
  }

  .selection-search-form {
    width: 100%;
    padding-inline: var(--gap-md);
    margin-bottom: var(--gap-base);

    .mat-mdc-form-field-infix {
      padding-top: var(--gap-sm);
      padding-bottom: var(--gap-sm);
    }
  }

  .selection-list {
    max-height: var(--selection-list-max-height);
    font-size: var(--text-base);
    margin-top: 0;
    overflow-y: auto;
    font-weight: var(--font-semi-bold);
  }

  .selection-list-item {
    display: flex;
    justify-content: space-between;
    padding-block: var(--gap-base);
    padding-inline: var(--gap-md);

    .list-item-label {
      padding: 0;
    }

    input[type="checkbox"] {
      width: var(--gap-md);
      height: var(--gap-md);
      appearance: none;
      outline: none;
      background-color: #f5f5f5;
      border: 1px solid var(--color-primary-fg);
      position: relative;
      cursor: pointer;

      &:checked {
        background-color: #91466a;
        border: 0;

        &::after {
          content: "";
          background: url('data:image/svg+xml,<svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.5303 0.71967C12.8232 1.01256 12.8232 1.48744 12.5303 1.78033L5.03033 9.28033C4.73744 9.57322 4.26256 9.57322 3.96967 9.28033L0.21967 5.53033C-0.0732233 5.23744 -0.0732233 4.76256 0.21967 4.46967C0.512563 4.17678 0.987437 4.17678 1.28033 4.46967L4.5 7.68934L11.4697 0.71967C11.7626 0.426777 12.2374 0.426777 12.5303 0.71967Z" fill="white"/></svg>');
          position: absolute;
          top: 50%;
          left: 50%;
          height: 10px;
          width: 13px;
          transform: translate(-50%, -50%);
        }
      }

      &.multi-select {
        border-radius: var(--rounded-sm);
      }

      &.single-select {
        border-radius: var(--rounded-full);
      }
    }

    &:not(:has(input[type="checkbox"]:disabled)) {
      cursor: pointer;

      &:hover {
        background-color: var(--color-primary-fg);
      }
    }

    &:has(input[type="checkbox"]:disabled) {
      color: var(--text-input-color) !important;
      input[type="checkbox"]:checked {
        background-color: var(--text-input-color) !important;
      }
    }
  }

  .selection-dialog-actions {
    padding: var(--gap-base) var(--gap-md);
    justify-content: space-between;
    background-color: var(--color-primary-fg);

    a {
      cursor: pointer;
    }
  }

  .close-button {
    position: absolute;
    top: var(--gap-base);
    right: var(--gap-base);
  }

  button {
    padding-block: var(--gap-base);
    padding-inline: var(--gap-md);
    border-radius: var(--button-radius);
  }

  .confirm-button {
    background-color: var(--color-primary);
    color: var(--color-primary-fg);
    font-weight: var(--font-semi-bold);
    font-size: var(--text-base);
    height: var(--button-height);
  }

  .cancel-button {
    .label {
      text-decoration: underline;
      font-size: var(--text-base);
    }
  }
}
